.footers{
    padding: 8px;
    background-color: #e5e6e7;
    text-align: center;
    color: black;
    font-weight: 500;
    font-size: 12px;
    /* bottom: 0px;
    position: absolute;
    width: -webkit-fill-available; */
}