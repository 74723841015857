.navbar-nav .nav-item {
  padding: .2rem 1rem;
  color: white;
  font-size: 10px;
}

.navbar-nav {
  font-size: 0.5rem;
}

.navbar-nav .nav-item .link {
  font-size: 0.9rem;
  font-weight: 400;
  color: rgb(41, 40, 40);
  opacity: 90%;
  text-decoration: none;
}

.navbar-nav .nav-item .link.active {
  color: black;
  opacity: 90%;
  font-weight: 400;
  position: relative;
}

.navbar-nav .nav-item .link.active::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: black;
}

/* main section css */
.main-section {
  background-image: url('../../../assets/images/erp-bg.jpg');
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.bgoffsetbg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nopadding {
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 768px) {
  .loginbg {
    max-width: 600px;
    margin: 0 auto;
  }
}

.logowidth img {
  width: auto;
  height: 190px;
}

.form-top {
  background: transparent;
  overflow: hidden;
  padding: 0 25px 15px 25px;
  border-radius: 4px 4px 0 0;
  text-align: center;
}

/* .form-top-left {
  padding-top: 50px;
  padding-bottom: 8px;
} */
.form-bottom {
  background: transparent;
}

.form-bottom h3 {
  line-height: 30px;
}

.form-bottom {
  padding: 25px 25px 30px 25px;
  /* background: #eee;
  -moz-border-radius: 0 0 4px 4px;
  -webkit-border-radius: 0 0 4px 4px; */
  border-radius: 0 0 4px 4px;
  /* text-align: left; */
}

.has-feedback {
  position: relative;
}

.form-group {
  margin-bottom: 15px;
}

.input-text {
  height: 50px;
  border: 1px solid #dfdfdf !important;
  background: transparent;
  border-radius: 4px;
  padding-left: 1.5rem;
  box-shadow: 0px 2px 15px rgb(0 0 0 / 10%);
  margin-bottom: 25px;
}

.form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 40px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  pointer-events: none;
}

.form-bottom form button.btn {
  width: 100%;
  box-shadow: 0px 2px 15px rgb(0 0 0 / 10%);
}

button.btns {
  height: 50px;
  line-height: 50px;
}

a.forgot,
button.btns {
  overflow: hidden;
  position: relative;
}

button.btns {
  height: 40px;
  margin: 0;
  padding: 0 20px;
  vertical-align: middle;
  /* background: #35aa47; */
  background: #0070e1;
  border: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 40px;
  color: #fff;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  text-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  -o-transition: all .3s;
  -moz-transition: all .3s;
  -webkit-transition: all .3s;
  -ms-transition: all .3s;
  transition: all .3s;
}

button.btns:hover {
  background: #007ffe;
  color: #fff;
}

.font-white {
  color: #444;
  font-size: 19px;
  margin-top: 0;
  font-weight: 500;
  margin-bottom: 15px;
}

.justifieds {
  /* display: table; */
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
  float: none;
}

.width50,
.width100,
.width150 {
  border-top: none;
  border-bottom: none;
  border-left: none;
  border-right: none;
  padding: 8px 0px;
}


.btn-group {
  position: relative;
  /* display: inline-block; */
  vertical-align: middle;
}

.forgot,
button.btn {
  overflow: hidden;
  position: relative;
}

.forgot {
  display: inline-block;
}

.forgot {
  color: #535353;
  text-decoration: none;
  font-size: .85rem;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 600;
  margin-top: 5px;
}

.pull-right {
  float: right !important;
}

.nopadding-2 {
  padding: 0 !important;
  margin: 0 !important;
}

@media (min-width: 1200px) {
  .col-lg-8 {
    width: 66.66666667%;
  }
}

.flex-column {
  flex-direction: column !important;
}

.text-wrap {
  position: relative;
  height: 100vh;
  z-index: 0;
  box-sizing: border-box;
  color: #fff;
  width: 100%;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #3a5061; */
}

@media (min-width: 768px) {
  .bg-position-lg-center {
    background-position: top center !important;
  }
}

.bg-shadow-remove {
  width: 90%;
}

.h3 {
  color: #fff;
  text-align: center;
  line-height: normal;
  font-weight: 800;
  margin-bottom: 2rem;
  font-size: 25px;
}
.input-text:-webkit-autofill {
  background-color: #0162c4 !important; /* Black background */
  -webkit-box-shadow: 0 0 0px 1000px #0162c4 inset !important;
  color: #ffffff !important;
  caret-color: #ffffff; /* Set caret (cursor) color */
}
.input-text:-webkit-autofill::first-line {
  color: #ffffff !important; /* Text inside autofill remains white */
}
.input-text::selection {
  background-color: #ffffff; /* Set selection background to white */
  color: #fff !important; 
}

.messages {
  padding-right: 20px;
}

.messages {
  padding-left: 15px;
}

a.more {
  text-decoration: none;
  color: #35aa47;
  font-size: 12px;
}

.more {
  display: block;
}

.logdivider {
  background: rgba(255, 255, 255, 0.40);
  clear: both;
  width: 100%;
  height: 1px;
  margin: 15px 0 15px;
}

.mCustomScrollbar {
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom;
}

.loginright {
  text-align: left;
  color: #fff;
  overflow: auto;
  position: relative;
  max-height: 350px;
  height: 330px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: rgb(0 0 0 / 50%);
  padding: 2.8rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 0 1px rgb(53 72 91 / 7%), 0 2px 2px rgb(0 0 0 / 1%), 0 4px 4px rgb(0 0 0 / 2%), 0 10px 8px rgb(0 0 0 / 3%), 0 15px 15px rgb(0 0 0 / 3%), 0 30px 30px rgb(0 0 0 / 4%), 0 70px 65px rgb(0 0 0 / 5%);
}

.loginright p {
  line-height: 18px;
  font-size: 13px;
  word-break: break-all;
}

.loginright h4 {
  font-weight: normal;
  line-height: normal;
  font-size: 18px;
}

.mCSB_container {
  position: relative;
  top: 0px;
  left: 0px;
  overflow: hidden;
  width: auto;
  height: auto;
}

.messages {
  padding-right: 20px;
}

.messages {
  padding-left: 15px;
}


.mainss {
  background-image: url("../../../assets/images/erp1-bg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  /* Center the image */
  background-size: cover;
  /* Resize the background image to cover the entire container */
  /* padding-top: 50px; */
  height: 100vh;
}

.login-form {
  background: #0162c4;
  margin-top: 120px;
  /* margin-bottom: 100px; */
  padding: 70px;
  border-radius: 5px;
  color: white;
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
}

.form-username::placeholder {
  color: #fff;
}

.form-password::placeholder {
  color: #fff;
}
.txtUser::placeholder{
  color: #fff;
}
.otp-text{
  font-style: italic;
  color: #fff;
}
.otp-text:hover{
color: #03034e;
}
.login-text:hover{
  color: #03034e;
}
.email_success {
  box-shadow: rgba(5, 102, 213, 0.4) 0px 0px 0px 2px, rgba(9, 65, 129, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  background-color: #0070e1;
  border: 1px solid #0070e1;
  padding: 5px 0px;
  text-align: center;
  border-radius: 2px;
  font-weight: 600;
  font-size: 12px;
}
