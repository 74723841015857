.password-section{
    background-image: url("../assets//images//login-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
}
.form-border{
    border: 1px solid rgb(230, 220, 208);
    background-color: #ffffffa6;
    padding: 30px 20px;
    border-radius: 5px;
    position: absolute;
    top: 20%;
    left: 27%;
}