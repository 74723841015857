* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    font-size: 11pt;
    font-family: 'Roboto', sans-serif;
    /* font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; */
    /* font-family: cursive; */
}

.form-select:focus{
    box-shadow: 0 0 0 1px rgb(3, 196, 3);
    border-radius: 2px;
    background-color: #ffffff0d;
    border: none;
    overflow: hidden;
}
input[type="text"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
input[type="file"]:focus,
input[type="date"]:focus,
textarea[type="text"]:focus,
.form-control:focus,
.form-control textarea:focus,
.form-group select:focus,
#note:focus,
.route-card select:focus {
    box-shadow: 0 0 0 1px rgb(3, 196, 3);
    border-radius: 2px;
    background-color: #ffffff0d;
    border: none;
}

input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"],
input[type="file"],
input[type="date"],
input[type="time"],
textarea[type="text"],
#note,
.form-group select,
.form-group textarea,
.route-card select 
.form-control
{
    /* box-shadow: 0 0 0 1px  rgb(31, 30, 30); */
    border: 1px solid rgb(155, 151, 151);
    border-radius: 4px;
    background-color: #ffffff0d;
    font-size: 14px;
    height: 40px;
    border: 1px solid #dfdfdf !important;
    /* background: transparent; */
    border-radius: 4px;
    padding-left: 1.5rem;
    box-shadow: 0px 2px 15px rgb(0 0 0 / 10%);
}

/* pagination */
.mdb-datatable-filter input.form-control{
    border: 1px solid  rgb(199, 198, 198);
    border-radius: 5px;
  }
  .sorting_desc{
    font-weight: 600;
  }
  .dataTables_length{
    display: none;
  }
  .dataTable thead{
    font-weight: 500;
  }
  .dataTables_info{
    font-size: 9pt;
    margin-top: 5pt;
    color: rgb(138, 5, 5);
  }
  .pagination{
    float: right;
  }
  .pagination .page-item{
    border: none;
    cursor: pointer;
  }
  .pagination .page-link{
    cursor: default;
    font-size: 9pt;
    border: none;
    border-radius: 0px;
    color: rgb(138, 5, 5);
  }
  .pagination li.active .page-link{
  color: white;
  }
  .sr-only{
    display: none;
  }
  tbody + thead{
    display: none;
  }
  .modal{
    --bs-modal-width: 80%;
  }
  .modal-content .card-text{
    font-size: 10pt;
  }
  .modal-body .form-control{
    border: solid 1px rgb(220, 219, 219);
    box-shadow: 0px 2px 15px rgb(0 0 0 / 10%);
    border-radius: 0px;
  }
  .modal-title{
    font-family: 'Roboto','Source Sans Pro',sans-serif;
    font-size: 13pt;
    font-weight: 550;
    opacity: .9;
  }
  .single-vehicle{
    font-size: 10pt;
    font-weight: 550;
    opacity: .9;
    font-family: 'Roboto','Source Sans Pro',sans-serif;
  }
  /* End */

  /* Loader css */
  .spinner-box {
    width: 300px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
  }
  .circle-border {
    width: 150px;
    height: 150px;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: rgb(63,249,220);
    background: linear-gradient(0deg, rgba(63,249,220,0.1) 33%, rgba(63,249,220,1) 100%);
    animation: spin .8s linear 0s infinite;
  }
  
  .circle-core {
    width: 100%;
    height: 100%;
    background-color: #1d2630;
    border-radius: 50%;
  }
  @keyframes spin {
    from {
      transform: rotate(0);
    }
    to{
      transform: rotate(359deg);
    }
  }
  .MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none !important;
}
