/* Navbar header css start */
.college-logo {
  width: 36px;
  height: 35px;
  border-radius: 50%;
  position: relative;
  left: 5px;
}

.college-logo-side {
  /* position: relative; */
  right: 5px;
  text-align: center;
  color: #b0dd38;
  border-radius: 4px;
  font-weight: 600;
  font-size: 18px;
}

.college-name {
  /* float: left; */
  background-color: transparent;
  background-image: none;
  /* padding: 10px 0px 10px 10px; */
  /* overflow: hidden; */
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #007fbf;
  font-size: 18px;
  font-weight: bold;
  /* letter-spacing: 1px; */
  font-family: cursive;
}

.header_search_form .input-group {
  background-color: white;
  border-radius: 5px;
}

/* Navbar header css end */

/* Profile Drop down css start */
.topuser-image {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: none;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  left: auto;
  float: left;
  min-width: 160px;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  visibility: visible;
  padding: 1px 0 0 0;
  border-top-width: 0;
  width: 280px;
  border-color: #eee;
}

.sstopuser {
  position: relative;
  clear: both;
  padding: 10px;
}

.ssuserleft {
  float: left;
  padding-right: 10px;
  padding-bottom: 10px;
}

.sstopuser img {
  height: 90px;
  width: 90px;
  border-radius: 3px;
  object-fit: cover;
  object-position: top center;
}

.sstopuser-test h4 {
  margin-top: 0;
}

.sstopuser-test h5 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  font-family: "Roboto", "Source Sans Pro", sans-serif;
  font-weight: 500;
  line-height: 1.1;
  text-transform: capitalize;
}

.text-capitalize {
  text-transform: capitalize;
  font-family: "Roboto", "Source Sans Pro", sans-serif;
  font-size: 18px;
}

.role-name {
  text-transform: capitalize;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  color: white;
}

.dropdown-menu .divider {
  padding-left: 0 !important;
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background: #e4e3e3;
  clear: both;
}

.sspass {
  display: flex;
  justify-content: space-between;
}

.sspass .links {
  clear: none !important;
  color: #333 !important;
  text-decoration: none;
  cursor: pointer;
}

.links:hover {
  color: rgb(3, 101, 230) !important;
}

/* Profile Drop down css end */

/* admin home page */
.data-icon {
  width: 60px;
  height: 60px;
  margin-left: 10px;
  margin-top: 10px;
  padding: 5px;
  border-radius: 5px;
  background-color: orange;
  color: white;
}

.text-muteds {
  font-size: 13pt;
  font-weight: 600;
  opacity: 1;
  color: rgb(230, 5, 5);
}

.card-data {
  text-align: center;
  padding: 10px;
}

.card-data .card-body .card-title {
  color: whitesmoke;
  font-weight: 700;
}

.card-title {
  font-size: 17px;
  font-weight: 500;
}

.admin-home-heading {
  margin-top: 60px;
  text-align: center;
  font-size: 22pt;
  font-family: "fangsong";
}

/* admin home page end */
.my-form {
  display: flex;
  justify-content: space-around;
}

.register-heading {
  font-size: 20px;
  margin: 25px 0px;
  font-weight: 500;
  font-family: "Roboto", "Source Sans Pro", sans-serif;
  /* text-decoration:underline; */
}

.for-label {
  color: rgb(26, 24, 24);
  font-weight: 400;
  font-size: 11pt;
  margin-top: 6px;
  font-family: "Roboto", "Source Sans Pro", sans-serif;
}

/* Admin Staff related css */
.stud_import {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.staff-import-heading {
  color: rgb(7, 7, 7);
  font-weight: 500;
  font-size: 19px;
  padding-bottom: 5px;
  /* border-bottom: 1px solid #ccc; */
}

.add_grade_section {
  padding: 20px;
  margin-bottom: 25px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 2px;
}

.import-guide ol li {
  font-size: 13px;
  font-weight: 500;
  font-family: "Roboto", "Source Sans Pro", sans-serif;
}

.table-sample {
  overflow-x: auto;
}

.table-guide .para {
  font-size: 13px;
  font-weight: 500;
  font-family: "Roboto", "Source Sans Pro", sans-serif;
}

.table-guide th {
  font-size: 0.71rem;
  font-weight: 600;
}

.table-guide tbody {
  font-size: 10px;
}

.no-select-message {
  background-color: #dae8f2 !important;
  color: #3498db !important;
  border-color: #a3c8e3 !important;
  padding: 15px 0px;
  border-radius: 5px;
}

.no-select-message span {
  font-size: 13px;
  padding-left: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-control {
  border: 1px solid rgb(112, 111, 111);
  border-radius: 0;
  box-shadow: none;
}

.form-group .req {
  color: #fc2d42;
}

.pagetitleh2 {
  background: #bebcbc;
  margin: 0;
  font-size: 18px;
  padding: 8px 5px;
  color: #000;
  border-bottom: 1px solid #d7dfe3;
  text-transform: capitalize;
}

.pull-right {
  float: right !important;
}

.pt3 {
  padding-top: 3px;
}

label {
  font-family: "Roboto-Medium";
  font-weight: 600;
  font-size: 11pt;
  margin-bottom: 5px;
  opacity: 0.9;
}

.active-list {
  background-color: orange;
  transition: all ease-in-out;
  color: black;
  padding: 2px 3px;
  border-radius: 2px;
}

.active-list:focus {
  transition: #444 250ms ease-in-out, red 250ms ease-in-out;
}

.errors {
  color: #ff3860;
  font-size: 11px;
}

.staff-image {
  width: 90px;
  height: 90px;
}

.staffinfo-box {
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  border: 1px solid #e4e4e4;
  width: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  margin-bottom: 15px;
  padding-bottom: 20px;
}

.img_div_modal {
  position: relative;
}

.overlay-content {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0;
  background: black;
  color: white;
  border-radius: 2px;
  transition: 0.4s ease-out;
}

.overlay-content p {
  position: relative;
  top: 40%;
  left: 40%;
}

.overlay-content .action-btn {
  font-size: 22px;
}

.img_div_modal:hover .overlay-content {
  opacity: 0.7;
}

.staffleft-box {
  float: left;
  padding-right: 10px;
}

.staffleft-content {
  overflow: hidden;
}

.paginate {
  overflow-x: auto;
}

.staffinfo-box h5 {
  display: block;
  margin-bottom: 5px;
  margin-top: 5px;
  font-family: "Roboto-Bold";
  font-size: 15px;
}

.staffinfo-box p {
  margin-bottom: 1px;
  display: block;
  font-size: 11px;
  line-height: normal;
}

.staffsub {
  padding-top: 4px;
  display: inline-block;
}

.staffinfo-box p span {
  background-color: #e2e2e2;
  border: 1px solid #c3c3c3;
  border-radius: 2px;
  padding: 2px 3px;
  text-align: center;
  color: #424242;
  line-height: 18px;
}

h5,
h6 {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  line-height: 1.1;
  color: inherit;
}

@media screen and (min-width: 1361px) and (max-width: 1440px) {
  .staffinfo-box p span {
    display: inline-grid;
    /* margin-top: 0.1rem; */
    margin: 0px 1px;
  }
}

@media screen and (max-width: 1120px) {
  .staffinfo-box p span {
    display: block;
    /* margin-top: 0.1rem; */
    margin: 2px 5px;
  }
}

@media (min-width: 1361px) and (max-width: 1440px) {
  .staffinfo-box {
    display: flex;
    align-content: stretch;
    min-height: calc(100vw / 10.5);
    max-height: 100%;
  }
}

/* End */

/* student related css */
.attendence-status {
  display: flex;
  justify-content: space-evenly;
}

.attendence-btn {
  display: flex;
  justify-content: space-between;
  /* margin-top: 50px; */
}

.label {
  font-size: .85rem;
  font-weight: 400;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

table,
tr,
th {
  font-size: 14px;
  font-weight: 500;
}

table,
tr,
td {
  font-size: 13px;
  font-weight: 400;
}

.download-option span {
  border: 1px solid rgb(206, 202, 202);
  margin: 0px 8px;
  padding: .28rem .5rem;
  cursor: pointer;
  border-radius: 7%;
  font-size: 0.7rem;
}

.form-select,
.form-control {
  border-radius: 0px;
  border: 1px solid rgb(220, 219, 219);
  box-shadow: 0px 2px 15px rgb(0 0 0 / 10%);
}

.astrick {
  color: red;
  font-size: 14px;
}

.student-details .card-body .card-title {
  font-size: 18px;
  font-weight: 600;
  opacity: 0.7;
  text-transform: uppercase;
  font-family: "Roboto", "Source Sans Pro", sans-serif;
}

.student-details .card-body .card-text {
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto", "Source Sans Pro", sans-serif;
}

.student-image {
  width: 130px;
  height: 130px;
}

.transport-details,
.hostel-details {
  font-size: 18px;
  font-weight: 500;
  font-family: "Roboto", "Source Sans Pro", sans-serif;
  border: none;
  background-color: rgb(219, 218, 218);
  padding: 5px 10px;
}

.student-delete {
  display: flex;
  justify-content: space-between;
}

.student-search-box {
  padding: 10px 0px;
  box-shadow: rgba(14, 63, 126, 0.06) 0px 0px 0px 1px, rgba(42, 51, 70, 0.03) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 2px 2px -1px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.03) 0px 5px 5px -2.5px, rgba(42, 51, 70, 0.03) 0px 10px 10px -5px, rgba(42, 51, 70, 0.03) 0px 24px 24px -8px;
}

/* End */

/* Admin course related css */
.course {
  font-size: 18px;
  font-weight: 500;
  font-family: "Roboto", "Source Sans Pro", sans-serif;
}

.table {
  border: 1px solid rgb(228, 227, 227);
}

/* End */

/* Teacher attendence css */
.find-b {
  color: #fdfdfd;
  background-color: #2a3f54;
  border-color: #2a3f54;
  padding: 3px 10px;
  border-radius: 3px;
  margin-top: 0.4rem;
}

.teacher-attend-date {
  padding: 0.8rem 0rem;
  background-color: rgb(241, 237, 237);
  border: 1px solid rgb(241, 237, 237);
  color: #1f1e1e;
  font-weight: bold;
  font-family: "Roboto", "Source Sans Pro", sans-serif;
}

.teacher-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

/* End */
.custom_header {
  color: hsl(210, 76%, 31%);
}
.custom_headerss{
  text-transform: capitalize;
  color: hsl(210, 76%, 31%);
}

.column-title {
  background-color: #ccc8c8;
}

/* time table css */
.attachment-block {
  position: relative;
  width: 100%;
  border: 1px solid #f4f4f4;
  border-radius: 3px;
  padding: 5px;
  /* margin-bottom: 10px; */
  background: #fff;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  font-size: 12px;
}

.attachment-left-space {
  padding-bottom: 0.3rem;
  color: #faa21c;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  /* background-color: #c8cbcc;
  border: 1px solid #c8cbcc; */
}

.attachment-block:hover .overlay {
  opacity: 1;
}

.attachment-block:hover {
  opacity: 1;
}

.text {
  color: white;
  font-size: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.timetable-message {
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  margin: 10px 10px;
  padding: 3px 0px;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-size: 16px;
  font-weight: bold;
  border-radius: 2px;
  background: #FBEEE6;
}

/* loader */
.loaderss {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 40vh; /* Full height of the viewport */
  position: relative;
}
