.main {
  background-image: url("../assets/images/iec-login-bg.jpg");
  background-size: cover;
  height: 100vh;
}

.welcome_login {
  text-align: center;
  /* font-size: 1.rem; */
  font-weight: 700;
}

.shadow {
  background-color: white;
  opacity: 90%;
  border: 1px solid #FFF2D7;

}

.d-grid {
  background-color: #1ab394;
  border-color: #1ab394;
  color: #FFFFFF;
  border-radius: 5px;
}

.form-label {
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: 500;
}

.log-head {
  font-size: 18pt;
  font-weight: 500;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus {
  box-shadow: 0 0 0 1px rgb(59, 59, 59);
  background-color: #ffffff0d;
}

.role-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.select-role {
  font-size: 12pt;
  font-weight: 600;
}

.role1,
.role2,
.role3 {
  font-size: 10pt;
  color: white;
  font-family: "Roboto", "Source Sans Pro", sans-serif;
}

.role1 {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.role2 {
  border-radius: 0px;
}

.role3 {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.forgot-password {
  color: #337ab7;
  text-decoration: none;
  font-size: 11pt;
  background-color: transparent;
}

.forgot-password:hover {
  color: #68a6db;
}

.email_error {
  border: 1px solid #c21807;
  background-color: #c21807;
  padding: 5px 0px;
  text-align: center;
  border-radius: 2px;
  font-weight: 600;
  font-size: 12px;
}

