// Here you can add other styles
.sidebar-nav .nav-link{
    color: white;
    font-size: 0.78rem;
    font-weight: 400;
    // font-family: 'Roboto',sans-serif;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.sidebar-nav .nav-link.active{
    background: #070707;
}
.sidebar-nav .nav-icon{
    font-size: 0.85rem;
    color: white;
    font-weight: 600;
}
.sidebar-nav .sub-nav-icon{
    font-size: 0.8rem;
    color: white;
    font-weight: 600;
}
.sidebar-nav .nav-group-items{
    margin-left: 5px;
}
.sidebar-nav .nav-group.show .nav-group-toggle{
    color: #faa21c;
}
.sidebar-nav .nav-group.show .nav-group-items .nav-item .nav-link.active{
    color: #faa21c;
}
.sidebar-nav .nav-link:hover{
    color: #faa21c;
    border-left: 2px solid #DC700E;
    overflow: hidden;
}
.sidebar-nav .nav-group-items .nav-link{
    padding: 3px;
}

html:not([dir=rtl]) .sidebar-nav .nav-group-items .nav-link{
    padding-left: 0px;
}
.sidebar:not(.sidebar-end) ~ * {
    --cui-sidebar-occupy-start: 13.2rem;
}
